
import pat from "../../images/scania.png"
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import Dropdown from 'react-bootstrap/Dropdown';


import './Navigation.css';
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../api/axios";
import { useDispatch } from "react-redux";
import { logOutUser } from "../../redux/reducers/userReducer";

const Navigation = ({userAttributes, token}) => {
 
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const Logout = async (e) => {
        e.preventDefault();
        try{
            const response = await axiosInstance.get('auth/logout');
          
            if(response.status){
                dispatch(logOutUser());
            }
        }catch{}
    }

    return (
        <div className="navigation">
            <div className="logoNav" onClick={()=> window.location.pathname = '/'}>
                <img src={pat} alt="" />
            </div>
            <div className="contNav">
                {token && 
                <>
                <FavoriteBorderIcon />
                <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                        <div>
                            <div className="email_name">{userAttributes?.email}</div>
                            <div className="role_name">{userAttributes?.role?.name}</div>
                        </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {userAttributes?.role?.id === 1 &&
                            <Dropdown.Item href="adminpanel">Admin Panel</Dropdown.Item>
                        }
                        <Dropdown.Item href="profile">Profile</Dropdown.Item>
                        <Dropdown.Item href="purchases">My purchases</Dropdown.Item>
                        <Dropdown.Item onClick={(e) => Logout(e)}>Log Out</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                </>
                }
                {!token && 
                    <Button onClick={() => navigate('/login')} className="btn-logIn">Log in</Button>
                }
            </div>
        </div>
    );
}

export default Navigation;