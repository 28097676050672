

import React, { useEffect, useState } from 'react';
import '../create/CreateExport.css';
import OwnTruck from './ExportDisplayOwn';
import CuzdiTruck from './ExportDisplayCuzdi';
import './DisplayS.css'
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import { useParams } from 'react-router-dom';
import axiosInstance from '../../../api/axios';

const ExportDisplay = () => {
    const { id } = useParams();
    const [selectedValue, setSelectedValue] = useState(1);
    const [exportR, setExport] = useState(null)

    useEffect(() => {
        getExport(id);
    }, [id])

    const getExport = async (id) => {
        try{
            const response = await axiosInstance.get('admin/exports/'+id);
            if(response.status){
                if(response.data?.data){
                    setExport(response.data.data)
                }
            }
        }catch{}
    }

    return (
        <div className="users">
        <div className="contentDD">
            <div className="mfd">
                <KeyboardDoubleArrowLeftIcon onClick={() => window.location.pathname = "adminpanel/exports"} />
            </div>
            <div className='userInfC'>
                <div className='partDetailsW'>
                    <span>Edit Export</span>
                </div>
                {exportR
                    ? (
                        exportR.type === 1 ? (
                            <OwnTruck exportR={exportR}/>
                        ) : (
                            <CuzdiTruck exportR={exportR}/>
                        )
                    )
                    : null}
            </div>
        </div>

    </div>
    );
}

export default ExportDisplay;