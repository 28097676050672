import './Exports.css';

import SearchIcon from '@mui/icons-material/Search';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { Fragment, useEffect, useState } from 'react';
import axiosInstance from '../../../api/axios';
import { Button, ButtonGroup } from '@mui/material';

const PurchasesAdmin = () => {
    const [purchases, setPurchases] = useState(null);
    const [total_pages, setTotal] = useState(1)
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState('');

    const [opened, setOpened] = useState(-1);
    const [newQuantity, setNewQuantity] = useState(0);
    const [error, setError] = useState();

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const configRequest = async (type, id) => {
        try{
            const result = await axiosInstance.post(`admin/purchases/${id}/${type}`);
            if(result.status){
                let newArr = [...purchases]; // copying the old datas array
                // a deep copy is not needed as we are overriding the whole object below, and not setting a property of it. this does not mutate the state.
                let index = purchases.findIndex(x => {return x.id === id});
                if(index !== -1){
                    newArr[index] = result.data?.data;
                    setPurchases(newArr);
                }
            }
        }catch{}
    }
    
    useEffect(() => {
        getPurchases(1);
    }, [])

    const getPurchases = async (page) => {
        setPurchases(null);
        setCurrentPage(page)
        try{
            const response = await axiosInstance.get('admin/purchases?page='+page);
            if(response.status){
                if(response.data?.data){
                    setPurchases(response.data.data);
                }
                if(response.data?.meta){
                    setTotal(response.data.meta?.last_page)
                    setCurrentPage(response.data.meta?.current_page)
                }
            }
        }catch{}
    }

    const changeOpened = (id, quantity) => {
        setOpened(id);
        setNewQuantity(quantity);
    }


    const changeQuanity = async () => {
        setError();
        if(newQuantity > 0 && opened !== -1){
            const response = await axiosInstance.put('admin/purchases/'+opened+'?quantity='+newQuantity);
            if(response.status){
                setError('Quantity updated');
                let newArr = [...purchases]; // copying the old datas array
                // a deep copy is not needed as we are overriding the whole object below, and not setting a property of it. this does not mutate the state.
                let index = purchases.findIndex(x => {return x.id === opened});
                if(index !== -1){
                    newArr[index] = response.data?.data;
                    setPurchases(newArr);
                }
            }else{
                setError('Failed to update quantity.');
            }
        }else{
            setError('The quantity must be greater than 0');
        }
    }

    const FilterX = (x) => {
        return x.id.toString().toLowerCase().includes(search.toLowerCase()) || x.user?.first_name?.toLowerCase().includes(search.toLowerCase()) || x.uuser?.first_name?.toLowerCase().includes(search.toLowerCase()) ||
        x.user?.company_name?.toLowerCase().includes(search.toLowerCase()) || x.part?.name?.toLowerCase().startsWith(search.toLowerCase()) || x.part?.serial_number?.toLowerCase().startsWith(search.toLowerCase())
    }


    return(
        <div className='exports'>
        <div className='titl'>Purchases</div>
        <div className='partsC'>
            <div className='searchDiv'>
                <div className='searchCont'>
                    <input style={{width: '100%'}} id='searchInp' value={search} onChange={(e) => setSearch(e.target.value)} type="text" placeholder='Search by user/company, product name or serial number' />
                    <SearchIcon />
                </div>
            </div>
        </div>
        <div className='allparts'>
            {purchases && purchases.filter(x => FilterX(x)).map((val, key) => {
                return (
                    <Fragment key={key}>
                        <div className='singlePart'>
                            <div className='rightPP'>
                                <div className='infoPartt' style={{paddingRight: '1rem'}}>
                                    <div className='infE' style={{width: '10%'}}>
                                        <div className='namePart'>ID #{val.id}</div>
                                    </div>
                                    <div className='infE'>
                                        <div className='namePart'>{val.part?.name}</div>
                                        <div className='divImp'>From {val.user?.first_name + ' ' + val.user?.last_name + ' - ' + val.user?.company_name}</div>
                                    </div>
                                    <div className='infE'>
                                        <div className='oemPart'><span className='boldT'>OEM number:</span>{val.part?.oem}</div>
                                        <div className='serialnPart'><span className='boldT'>Serial Number:</span> {val.part?.serial_number}</div>
                                        <div className='serialnPart'><span className='boldT'>Status:</span>
                                        <span className={val.status === 1 ? 'status_pending' : val.status === 2 ? 'status_accepted' : 'status_rejected'}>
                                                {val.status === 1 ? ' Pending' : val.status === 2 ? ' Accepted' : ' Rejected'} </span> 
                                        </div>
                                    </div>
                                    <div className='infE'>
                                        <div className='oemPart'><span className='boldT'>Quantity:</span> {val.quantity} </div>
                                        <div className='serialnPart'><span className='boldT'>Price:</span> {val.part?.sale_price} €</div>
                                        <div className='serialnPart'><b><span className='boldT'>Total:</span> {val.quantity * val.part?.sale_price} €</b></div>
                                    </div>
                                    <div className='editBEP'>
                                            {val.status === 1 && 
                                            <>
                                                <ButtonGroup variant="outlined" aria-label="Basic button group">
                                                    <Button onClick={() => {configRequest("accept", val.id)}} className='accept_btn'>Accept</Button>
                                                    <Button onClick={() => {opened === val.id ? setOpened(-1) : changeOpened(val.id, val.quantity)}} className='edit_btn'>{opened === val.id ? "Close" : "Edit"}</Button>
                                                    <Button onClick={() => {configRequest("reject", val.id)}} className='reject_btn'>Reject</Button>
                                                </ButtonGroup>
                                            </>}
                                            {val.status !== 1 && 
                                            <>
                                                <ButtonGroup variant="outlined" aria-label="Basic button group">
                                                    {/* 
                                                    <Button onClick={() => {configRequest("pending", val.id)}} className='pending_btn'>RESET</Button>*/}
                                                    <Button onClick={() => {opened === val.id ? setOpened(-1) : changeOpened(val.id, val.quantity)}} className='edit_btn'>{opened === val.id ? "Close" : "Edit"}</Button>
                                                </ButtonGroup>
                                            </>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {opened === val.id &&
                            <div className='singlePart' style={{background: '#00000017', marginTop: '0', justifyContent: 'center', paddingTop: '1%'}}>
                                <form>
                                    <div className="form-row">
                                        <div className="form-group col-md-12">
                                            <label htmlFor="quantity">Quantity</label>
                                            <div className="form-row">
                                                <input type="text" name="quantity" value={newQuantity} onChange={(e) => setNewQuantity(e.target.value)} className="col-md-6 form-control" id="quantity" placeholder="" />
                                                <div className="col-md-6">
                                                    <Button onClick={() => changeQuanity()} style={{width: 'fit-content', fontSize: '.8rem'}} variant='outlined'>Save Changes</Button>
                                                </div>
                                            </div>
                                            <p style={{textAlign: 'start', paddingTop: '.4rem'}} className={error?.startsWith('Quantity') ? 'p-success' : 'p-error'} aria-live='assertive'>{error}</p>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        }
                    </Fragment>
                )
            })}


        </div>
        <div className='paginationD'>
        <nav aria-label="...">
                <ul className="pagination">
                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                        <span className="page-link" onClick={() => handlePageChange(currentPage - 1)}>
                            <KeyboardDoubleArrowLeftIcon />
                        </span>
                    </li>
                    {Array.from({ length: total_pages }, (_, index) => (
                        <li className={`page-item ${currentPage === index + 1 ? 'active' : ''}`} key={index}>
                            <span className="page-link" onClick={() => handlePageChange(index + 1)}>
                                {index + 1}
                                {currentPage === index + 1 && <span className="sr-only"></span>}
                            </span>
                        </li>
                    ))}
                    <li className={`page-item ${currentPage === total_pages ? 'disabled' : ''}`}>
                        <span className="page-link" onClick={() => handlePageChange(currentPage + 1)}>
                            <KeyboardDoubleArrowRightIcon />
                        </span>
                    </li>
                </ul>
            </nav>

        </div>
    </div>
    )
}

export default PurchasesAdmin;