import './Exports.css';

import SearchIcon from '@mui/icons-material/Search';
import { useEffect, useState } from 'react'
import ModeIcon from '@mui/icons-material/Mode';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { useNavigate } from "react-router-dom";
import axiosInstance from '../../../api/axios';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { Form } from 'react-bootstrap';

const Exports = () => {
    const [exports, setExports] = useState(null);
    const [total_pages, setTotal] = useState(1)
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState('');
    const [truckType, changeTruck] = useState(0);
    
    const navigate = useNavigate();
        
    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };
    
    useEffect(() => {
        getExports(1);
    }, [])

    const getExports = async (page) => {
        setExports(null);
        setCurrentPage(page)
        try{
            const response = await axiosInstance.get('admin/exports?page='+page);
            if(response.status){
                if(response.data?.data){
                    setExports(response.data.data);
                }
                if(response.data?.meta){
                    setTotal(response.data.meta?.last_page)
                    setCurrentPage(response.data.meta?.current_page)
                }
            }
        }catch{}
    }

    const FilterX = (x) => {
        return (x.part?.name.toLowerCase().includes(search.toLowerCase()) || x.part?.serial_number.toLowerCase().includes(search.toLowerCase()) || x.truck_registration?.toLowerCase().includes(search.toLowerCase()) ||
        x.user?.company_name?.toLowerCase().includes(search.toLowerCase()) ) && (parseInt(truckType) !== 0 ? parseInt(x.type) === parseInt(truckType) : true)
    }

    return (
        <div className='exports'>
            <div className='titl'>Exports</div>
            <div className='partsC'>
                <div className='searchDiv'>
                    <Form.Select onChange={(e) => changeTruck(e.target.value)} style={{marginRight: '1rem'}} size="sm" value={truckType} id='selectFilter' aria-label="Default select example">
                        <option value="0">All Trucks</option>
                        <option value="1">Own Truck</option>  
                        <option value="2">Cuzdi Truck</option>
                    </Form.Select>
                    <div className='searchCont'>
                        <input style={{width: '100%'}} id='searchInp' value={search} onChange={(e) => setSearch(e.target.value)} type="text" placeholder='Search by company/truck registration, part name or serial number' />
                        <SearchIcon />
                    </div>
                </div>
            </div>
            <div className='allparts'>
                {exports && exports.filter(x => FilterX(x)).map((val, key) => {
                    return (
                        <div className='singlePart' key={key}>
                            <div className='rightPP'>
                                <div className='infoPartt'>
                                    <div className='infE'>
                                        <div className='namePart'>{val.part?.name}</div>
                                        <div className='divImp'>{val.type === 1 ? 'Own Truck' : 'Cuzdi Truck'}, {val.type === 1 ? val.truck_registration : val.user?.first_name + ' ' + val.user?.last_name + ' - ' + val.user?.company_name}</div>
                                    </div>
                                    <div className='infE'>
                                        <div className='oemPart'><span className='boldT'>OEM number:</span>{val.part?.oem}</div>
                                        <div className='serialnPart'><span className='boldT'>Serial Number:</span> {val.part?.serial_number}</div>
                                        <div className='serialnPart'><span className='boldT'>Quantity:</span> {val.quantity}</div>
                                    </div>
                                    <div className='dateUpdatedE'>{val.notes}</div>
                                    <div className='editBE'>
                                    <ModeIcon onClick={() => {
                                    navigate(`/adminpanel/exports/${val.id}`);}}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}


            </div>
            <div className='paginationD'>
            <nav aria-label="...">
                    <ul className="pagination">
                        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                            <span className="page-link" onClick={() => handlePageChange(currentPage - 1)}>
                                <KeyboardDoubleArrowLeftIcon />
                            </span>
                        </li>
                        {Array.from({ length: total_pages }, (_, index) => (
                            <li className={`page-item ${currentPage === index + 1 ? 'active' : ''}`} key={index}>
                                <span className="page-link" onClick={() => handlePageChange(index + 1)}>
                                    {index + 1}
                                    {currentPage === index + 1 && <span className="sr-only"></span>}
                                </span>
                            </li>
                        ))}
                        <li className={`page-item ${currentPage === total_pages ? 'disabled' : ''}`}>
                            <span className="page-link" onClick={() => handlePageChange(currentPage + 1)}>
                                <KeyboardDoubleArrowRightIcon />
                            </span>
                        </li>
                    </ul>
                </nav>

            </div>
        </div>
    );
}

export default  Exports;