import React, { useState } from 'react';
import './CreateExport.css';
import OwnTruck from './OwnTruck';
import CuzdiTruck from './CuzdiTruck';


const CreateExport = () => {

    const [selectedValue, setSelectedValue] = useState(1);

    const handleSelectChange = (event) => {
        setSelectedValue(Number(event.target.value));
    };


    return (
        <div className='createExport' >
            <div className='titl'>Create Export</div>
            <div className='userInfC'>
                <div className='partDetailsW'>
                    <span>Export Details</span>
                    <select className="form-select form-select-sm" aria-label="Default select example"
                        value={selectedValue}
                        onChange={handleSelectChange}>
                        <option selected value={1}>Own Truck</option>
                        <option value={2}>Cuzdi truck</option>
                    </select>
                </div>
                {selectedValue === 1 ? (
                    <OwnTruck/>
                ) : selectedValue === 2 ? (
                    <CuzdiTruck/>
                ) : null}
            </div>
        </div>
    );
}

export default CreateExport;