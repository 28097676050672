import DisplayedParts from "../components/CatalogComponents/DisplayedParts";


const CatalogPage = ({token}) => {
    return (
        <div className="catalogPage">
            
            {/*<FilterItems/>*/}
            <DisplayedParts token={token}/>
        </div>
    );
}

export default  CatalogPage;