
import './Purchases.css';
import { useEffect, useState } from 'react';
import axiosInstance from '../api/axios';

const Purchases = () => {
    const [purchases, setPurchses] = useState(null)
    
    useEffect(() => {
        getPurchases();
    }, [])

    const getPurchases = async () => {
        setPurchses(null);
        try{
            const response = await axiosInstance.get('purchases');
            if(response.status){
                if(response.data?.data){
                    setPurchses(response.data.data);
                }
            }
        }catch{}
    }


    return (
        <div className='purchasess'>
            <div className='purchasesTitl'>My Purchases</div>
            <div className='containerP'>
                
            {purchases && purchases.map((val,key)=>{
                return(
                    <div className='singleCardPurc' key={key}>
                        <div className="imgD">
                                <img src={val.part?.part_picture} alt="" />
                            </div>
                            <div className="specsDD">
                                <div className="specsDleftP">
                                    <div className="nameD">{val.name}</div>
                                    <div className="oemD">
                                        <span className="boldv">OEM number: </span>
                                        {val.part?.oem}</div>
                                    <div className="serialN">
                                        <span className="boldv">Serial Number: </span>
                                        {val.part?.serial_number}</div>
                                    <div className="serialN">
                                        <span className="boldv">Price per Part: </span>
                                        {val.part?.sale_price} €
                                    </div>
                                    <div className="serialN">
                                        <span className="boldv">Quantity: </span>
                                        {val.quantity}
                                    </div>
                                    <div className="priceDP">{val.part?.sale_price * val.quantity} €</div>
                                    <div>
                                        <span className={val.status === 1 ? 'status_pending' : val.status === 2 ? 'status_accepted' : 'status_rejected'}>
                                            {val.status === 1 ? 'Pending' : val.status === 2 ? 'Accepted' : 'Rejected'} </span> 
                                    </div>
                                </div>
                                <div className="specsDright">
                                    <div className="timeDP">{/*05 јан 01:55*/}</div>
                                </div>
                            </div>
                    </div>
                )
            })}
            </div>
        </div>
    );
}

export default  Purchases;