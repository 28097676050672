
import { useState } from 'react';
import './CreateUser.css'
import axiosInstance from '../../../api/axios';
import { Form } from 'react-bootstrap';

const EMAIL_REGEX = /^[a-zA-Z0-9.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
const CreateUser = () => {
    const [selectRole, setRole] = useState(2)
    const [details, setDetails] = useState({
        first_name: '',
        last_name: '',
        email: '',
        company_name: '',
        country: '',
        zip: '',
        city: '',
    })
    const [error, setError] = useState();

    const isObjectValuesNotEmpty = (obj) => {
        return Object.values(obj).every(value => value !== '');
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setDetails((prevUser) => ({
          ...prevUser,
          [name]: value,
        }));
    };

    function objectToFormData(obj) {
        const formData = new FormData();
      
        for (const key in obj) {
          if (obj.hasOwnProperty(key)) {
            formData.append(key, obj[key]);
          }
        }
      
        return formData;
    }

    const createUser = async (e) => {
        let role = parseInt(selectRole);
        e.preventDefault();
        setError();
        if(isObjectValuesNotEmpty(details) && (role === 1 || role === 2)){
            if(EMAIL_REGEX.test(details.email)){
                const formData = objectToFormData(details);
                formData.append('role_id', role);
                try{
                    const response = await axiosInstance.post('admin/users', formData)
                    if(response.status){
                        setError('User created successfully');
                    }
                }catch{
                    setError('Error while creating user.');
                }
            }else{
                setError('Invalid email address.');
            }
        }else{
            setError('Fill in the form.');
        }
    }

    return (
        <div className='createUser' >
            <div className='titl'>Create User</div>
            <div className='userInfC'>
                <div className='userDetailsW'>User Details</div>
                <div className='userD'>
                    <form>
                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="first_name">First Name</label>
                                <input type="text" value={details.first_name} name="first_name" onChange={handleInputChange} className="form-control" id="first_name"/>
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="last_name">Last Name</label>
                                <input type="text" value={details.last_name} name="last_name" onChange={handleInputChange} className="form-control" id="last_name"/>
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">Email</label>
                            <input type="text" value={details.email} name="email" onChange={handleInputChange} className="form-control" id="email"/>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="company_name">Company Name</label>
                                <input type="text" value={details.company_name}  name="company_name" onChange={handleInputChange} className="form-control" id="company_name"/>
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="company_name">Role</label>
                                <Form.Select value={selectRole} onChange={(e) => {setRole(e.target.value)}} aria-label="Default select example">
                                    <option value="2">User</option>
                                    <option value="1">Admin</option>
                                </Form.Select>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="country">Country</label>
                                <input type="text" value={details.country}  name="country" onChange={handleInputChange} className="form-control" id="country"/>
                            </div>
                            <div className="form-group col-md-4">
                                <label htmlFor="city">City</label>
                                <input type="text" value={details.city}  name="city" onChange={handleInputChange} className="form-control" id="city"/>
                            </div>
                            <div className="form-group col-md-2">
                                <label htmlFor="zip">Zip</label>
                                <input type="text" value={details.zip} name="zip"  onChange={handleInputChange}  className="form-control" id="zip"/>
                            </div>
                        </div>
                        <button onClick={(e) => createUser(e)} type="submit" className="btn btn-primary">Create</button>
                        <p style={{textAlign: 'start', paddingTop: '.4rem'}} className={error?.startsWith('User') ? 'p-success' : 'p-error'} aria-live='assertive'>{error}</p>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default CreateUser;