import { useEffect, useState } from 'react';
import './CreateExport.css';

import axiosInstance from '../../../api/axios';
import PaginatedSelect from '../../../helpers/PaginationSelect';
import PaginationUsers from '../../../helpers/PaginationUsers';

const CuzdiTruck = () => {
    const [parts, setParts] = useState(null);
    const [users, setUsers] = useState(null);
    const [details, setDetails] = useState({
        type: 2,
        quantity: '',
        notes: '',
    });
    const [part_id, setPartID] = useState();
    const [user_id, setUserID] = useState();

    const [error, setError] = useState();

    useEffect(() => {
        getParts();
        getUsers();
    }, [])

    const getParts = async () => {
        setParts(null);
        try{
            const response = await axiosInstance.get('admin/parts');
            if(response.status){
                if(response.data?.data){
                    setParts(response.data.data);
                }
            }
        }catch{}
    }

    const getUsers = async () => {
        setUsers(null);
        try{
            const response = await axiosInstance.get('admin/users');
            if(response.status){
                if(response.data?.data){
                    setUsers(response.data.data);
                }
            }
        }catch{}
    }

    const updateValueInParent = (newValue) => {
        setPartID(newValue);
    };

    const updateValueInParentUser = (newValue) => {
        setUserID(newValue);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setDetails((prevUser) => ({
          ...prevUser,
          [name]: value,
        }));
    };

    function objectToFormData(obj) {
        const formData = new FormData();
      
        for (const key in obj) {
          if (obj.hasOwnProperty(key)) {
            formData.append(key, obj[key]);
          }
        }
      
        return formData;
    }
    const isObjectValuesNotEmpty = (obj) => {
        return Object.values(obj).every((value) => value !== '' && value !== null);
    };

    const addExport = async (e) => {
        e.preventDefault();

        setError();

        if(isObjectValuesNotEmpty(details) && part_id && user_id){
            const formData = objectToFormData(details);
            formData.append('part_id', part_id);
            formData.append('user_id', user_id);
            try{
                const response = await axiosInstance.post('admin/exports', formData)
                
                if(response.status){
                    setError('Export created successfully');
                }
            }catch(err){
                if(err.response?.data?.message){
                    setError(err.response?.data?.message);
                }else{
                    setError('Error while creating export.');
                }
               
            }
        }else{
            setError('Fill in the form')
        }
    }

    return (
        <div className='cuzdiTruck' >
            <div className='titlBig'>Cuzdi Truck</div>
            <div className='mars'>
                <div className="form-group col-md-12">
                    <label htmlFor="part_id"> Product </label>
                    <PaginatedSelect updateValueInParent={updateValueInParent} value={parts}/>
                </div>
                <div className="form-group col-md-12">
                            <label htmlFor="part_id"> User </label>
                            <PaginationUsers updateValueInParent={updateValueInParentUser} value={users}/>
                </div>
            </div>
            <div className='rows'>
                <div className="form-row">
                    <div className="form-group col-md-6">
                        <label htmlFor="quantity"> Quantity </label>
                        <input type="number" name="quantity"  value={details.quantity} onChange={handleInputChange} className="form-control" id="quantity" placeholder='0' />
                    </div>
                </div>
            </div>

            <div className="mb-66">
                <label htmlFor="notes" className="form-label">Text about the PART</label>
                <textarea value={details.notes} name="notes" onChange={handleInputChange} className="form-control" id="notes" rows="3" placeholder=''></textarea>
            </div>
            <div className='brnc'>
                <button onClick={(e) => addExport(e)} type="submit" className="btn btn-primary">Create</button>
                <p style={{textAlign: 'start', paddingTop: '.4rem'}} className={error?.startsWith('Export') ? 'p-success' : 'p-error'} aria-live='assertive'>{error}</p>
            </div>

        </div>
    );
}

export default CuzdiTruck;