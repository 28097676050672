import { useState } from 'react';
import './CreatePart.css'
import axiosInstance from '../../../api/axios';


const CreatePart = () => {
    const [details, setDetails] = useState({
        name: '',
        oem: '',
        serial_number: '',
        purchase_price: '',
        sale_price: '',
    });
    const [error, setError] = useState();
    const [selectedFile, setSelectedFile] = useState(null);
    const [isFileValid, setFileValid] = useState(true);
    
    function objectToFormData(obj) {
        const formData = new FormData();
      
        for (const key in obj) {
          if (obj.hasOwnProperty(key)) {
            formData.append(key, obj[key]);
          }
        }
      
        return formData;
    }

    const createPart = async (e) => {
        e.preventDefault();

        if(isObjectValuesNotEmpty(details)){
            if(selectedFile && isFileValid){
                const formData = objectToFormData(details);
                formData.append('file', selectedFile)
                try{
                    const response = await axiosInstance.post('admin/parts', formData)
                    if(response.status){
                        setError('Part added successfully');
                    }
                }catch(err){
                    if(err.response?.data?.message){
                        setError(err.response?.data?.message);
                    }else{
                        setError('Error while creating part.');
                    }
                   
                }
            }else{
                setError('Fill in the form.');
            }
        }else{
            setError('Fill in the form.');
        }
    }
    
    const isObjectValuesNotEmpty = (obj) => {
        return Object.values(obj).every((value) => value !== '' && value !== null);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setDetails((prevUser) => ({
          ...prevUser,
          [name]: value,
        }));
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
    
        if (file) {
          // Check if the file is an image
          if (file.type.startsWith('image/')) {
            // Check if the file size is less than or equal to 500KB
            if (file.size <= 500 * 1024) {
              setSelectedFile(file);
              setFileValid(true);
            } else {
              // File size exceeds 500KB
              setSelectedFile(null);
              setFileValid(false);
            }
          } else {
            // File is not an image
            setSelectedFile(null);
            setFileValid(false);
          }
        } else {
          // No file selected
          setSelectedFile(null);
          setFileValid(false);
        }
      };

    return (
        <div className="createPart">
            <div className="titl">Create Part</div>
            <div className='partInfC'>
                <div className='partDetailsWP'>Part Details</div>
                <div className='partDP'>
                    <form>
                        <div className="form-row">
                            <div className="form-group col-md-4">
                                <label htmlFor="name">Part Name</label>
                                <input type="text" name="name" value={details.name} onChange={handleInputChange} className="form-control" id="name" placeholder="" />
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="serial_number">Serial Number</label>
                            <input type="text" name="serial_number" value={details.serial_number} onChange={handleInputChange} className="form-control" id="serial_number" placeholder="" />
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-3">
                                <label htmlFor="oem"> OEM Number</label>
                                <input type="number" name="oem" value={details.oem} onChange={handleInputChange} className="form-control" id="oem" placeholder="" />
                            </div>
                            <div className="form-group col-md-3">
                                <label htmlFor="purchase_price">Purchase Price</label>
                                <input type="number" name="purchase_price"  value={details.purchase_price} onChange={handleInputChange} className="form-control" id="purchase_price" placeholder='€' />
                            </div>
                            <div className="form-group col-md-3">
                                <label htmlFor="sale_price">Sale Price</label>
                                <input type="number" name="sale_price" value={details.sale_price} onChange={handleInputChange} className="form-control" id="sale_price" placeholder='€' />
                            </div>
                        </div>
                        <div className="custom-file">
                            <input 
                            type="file" 
                            accept="image/*"
                            className={`custom-file-input ${isFileValid ? '' : 'is-invalid'}`}
                            id="validatedCustomFile" 
                            required 
                            onChange={handleFileChange}
                            />
                            <div className="invalid-feedback">{isFileValid ? '' : 'Please choose a valid image (max 500KB).'}</div>
                        </div>
                        <button type="submit" onClick={(e) => createPart(e)} className="btn btn-primary">Create</button>
                        <p style={{textAlign: 'start', paddingTop: '.4rem'}} className={error?.startsWith('Part') ? 'p-success' : 'p-error'} aria-live='assertive'>{error}</p>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default CreatePart;