import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import AssessmentIcon from '@mui/icons-material/Assessment';


export const SidebarData = [
    {
        id: 0,
        title: 'Dashboard',
        icon: <DashboardIcon />,
        link: '/adminpanel',
        sub: false,

    },
    {
        id: 1,
        title: 'Users',
        icon: <PeopleIcon />,
        link: '',
        sub: true,
        open:false,
        childrens: [{
            id: 0,
            subTitle: 'Create User',
            subLink: '/adminpanel/createUser'
        },
        {
            id: 1,
            subTitle: 'Users',
            subLink: '/adminpanel/users'
        },
        ]
    },
    {
        id: 2,
        title: 'Parts',
        icon: <CollectionsBookmarkIcon />,
        link: '',
        sub: true,
        open:false,
        childrens: [{
            id: 0,
            subTitle: 'Create Part',
            subLink: '/adminpanel/createPart'
        },
        {
            id: 1,
            subTitle: 'Parts',
            subLink: '/adminpanel/parts'
        },
        ]
    },
    {
        id: 3,
        title: '    Export',
        icon: <ImportExportIcon />,
        link: '',
        sub: true,
        open:false,
        childrens: [{
            id: 0,
            subTitle: 'Create Export',
            subLink: '/adminpanel/createExport'
        },
        {
            id: 1,
            subTitle: 'Exports',
            subLink: '/adminpanel/exports'
        },]
    },
    {
        id: 4,
        title: 'Purchase',
        icon: <AssessmentIcon />,
        link: '',
        sub: true,
        open:false,
        childrens: [{
            id: 0,
            subTitle: 'View Purchases',
            subLink: '/adminpanel/purchases'
        },
    ]
    },
]
