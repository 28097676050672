import GroupIcon from '@mui/icons-material/Group';
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import AssessmentIcon from '@mui/icons-material/Assessment';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import ChartJs from './Chart'
import './Dashboard.css'
import { useEffect, useState } from 'react';
import axiosInstance from '../../api/axios';


const Dashboard = () => {
    const [dashborad, setDashboard] = useState(null);

    useEffect(() => {
        getDash()
    }, [])

    const getDash = async () => {
        try{
            const response = await axiosInstance.get('admin/dashboard')

            if(response.status){
                setDashboard(response?.data)
            }
        }catch{}
    }

    return (
        <div className="dashborad">
            <div className='titl'>Dashboard <span> Statistics Overview</span></div>
            <div className="contentD">
                <div className="singleCard">
                    <div className='topC'>
                        <div className="leftCard">
                            <GroupIcon />
                        </div>
                        <div className='rightCard'>
                            <div className="cardTitle">Users</div>
                            <div className="cardV">{dashborad?.total_number_of_users_created}</div>
                        </div>
                    </div>
                    <div className='bottomC'>
                        <div className='view'>View Users</div>
                        <ArrowCircleRightIcon />
                    </div>


                </div>
                <div className="singleCard">
                    <div className='topC'>
                        <div className="leftCard">
                            <CollectionsBookmarkIcon />
                        </div>
                        <div className='rightCard'>
                            <div className="cardTitle">Parts</div>
                            <div className="cardV">{dashborad?.total_number_of_parts_created}</div>
                        </div>
                    </div>
                    <div className='bottomC'>
                        <div className='view'>View Parts</div>
                        <ArrowCircleRightIcon />
                    </div>

                </div>
                <div className="singleCard">
                    <div className='topC'>
                        <div className="leftCard">
                            <ShoppingCartCheckoutIcon />
                        </div>
                        <div className='rightCard'>
                            <div className="cardTitle">Exports</div>
                            <div className="cardV">{dashborad?.total_number_of_exports_created}</div>
                        </div>
                    </div>
                    <div className='bottomC'>
                        <div className='view'>View Exports</div>
                        <ArrowCircleRightIcon />
                    </div>


                </div>
                <div className="singleCard">
                    <div className='topC'>
                        <div className="leftCard">
                            <AssessmentIcon />
                        </div>
                        <div className='rightCard'>
                            <div className="cardTitle">Purchases</div>
                            <div className="cardV">{dashborad?.total_number_of_purchases_created}</div>
                        </div>
                    </div>
                    <div className='bottomC'>
                        <div className='view'>View Purchases</div>
                        <ArrowCircleRightIcon />
                    </div>


                </div>
            </div>
            <div className='chartC'>
                <div className='chartT'><SignalCellularAltIcon /> Statistics</div>
                <div className='chartDiv'>
                    <ChartJs chartData={dashborad?.chart} />
                </div>
            </div>

        </div>
    );
}

export default Dashboard;