import { useState } from "react"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
export default function SidebarItem({item}){
    const [open, setOpen] = useState(false)

    
    if(item.childrens){
        return (
            <div className={open ? "sidebar-item open" : "sidebar-item"}>
                <div className="sidebar-title" onClick={() => setOpen(!open)}>
                    <span >
                        <div className="icons">{item.icon}</div>
                        <div className="titleSub"> {item.title} </div>
                    </span> 
                    <ExpandMoreIcon className="toggle-btn"/>
                </div>
                <div className="sidebar-content" >
                    { item.childrens.map((child, key) => {
                        return(
                            <div className="singleSub" key={key} onClick={() => child.subLink && (window.location.pathname = child.subLink)}><li>{child.subTitle}</li></div>
                        )
                    }) }
                    
                </div>
            </div>
        )
    }else{
        return (
            <a href={item.path || "#"} className="sidebar-item plain" onClick={() => item.link && (window.location.pathname = item.link)}>
                <div className="icons">{item.icon}</div>
                <div className="titleSub"> {item.title} </div>
            </a>
        )
    }
}