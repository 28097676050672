import React, { useEffect, useState } from 'react';
import Select from 'react-select';

const PaginationUsers = ({ user_id, updateValueInParent, value }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [mockDataReady, setMockDataReady] = useState(false);
  const [mockData, setMockData] = useState([]);

  const createMockData = (inputValue) => {
    return Array.from({ length: value ? value.length : 0 }, (_, index) => ({
      value: value[index].id,
      label: value[index].first_name + ' ' + value[index].last_name,
      company: value[index].company_name,
    }));
  };


  // Initialize MockData
  useEffect(() => {
    if(value){
      const data = createMockData(value);
      setMockData(data);
      setMockDataReady(true);
    }
  }, [value]);

  useEffect(() => {
    // Check if id is different from the selectedOption.value after MockData is loaded
    if (mockDataReady) {
      const selected = mockData.find((option) => option.value === user_id);
      setSelectedOption(selected);
    }
  }, [mockDataReady]);

  useEffect(() => {
    updateValueInParent(selectedOption?.value);
  }, [selectedOption, updateValueInParent]);


  const CustomOption = ({ innerProps, label, data }) => (
    <div {...innerProps} style={{ display: 'flex', alignItems: 'center' }}>
      <div style={{paddingLeft: '1rem'}}>
        <div>User: {label}</div>
        <div>Company: {data.company}</div>
      </div>
    </div>
  );

  const filterOption = (option, inputValue) => {
    const labelMatches = option.label.toLowerCase().includes(inputValue.toLowerCase());
    const customTextMatches = option.data.company.toLowerCase().includes(inputValue.toLowerCase());
    return labelMatches || customTextMatches;
  };

  const customStyles = {
    container: (provided) => ({
      ...provided,
      width: '100%', // Set the width of the entire Select component
    }),
    control: (provided) => ({
      ...provided,
      borderRadius: '5px', // Optional: Add border-radius for better appearance
    }),
  };

  useEffect(() => {
    const originalError = console.error;
    console.error = (...args) => {
      if (args.includes('Warning: Use the `defaultValue` or `value` props on <select> instead of setting `selected` on <option>.')) {
        return;
      }
      originalError.call(console, ...args);
    };

    return () => {
      console.error = originalError;
    };
  }, []);

  return (
    <Select
      defaultValue={selectedOption}
      value={selectedOption}
      onChange={(selected) => setSelectedOption(selected)}
      options={mockData}
      isSearchable
      menuPosition="absolute"
      menuPortalTarget={document.body}
      styles={customStyles}
      components={{
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
        Option: CustomOption,
      }}
    />
  );
};

export default PaginationUsers;