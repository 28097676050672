import { useParams } from "react-router-dom";
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import './DisplayS.css';
import { useEffect, useState } from "react";
import axiosInstance from "../../../api/axios";
import { Form } from "react-bootstrap";

const EMAIL_REGEX = /^[a-zA-Z0-9.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
const UserDisplay = () => {
    const { id } = useParams();
    const [error, setError] = useState();
    const [details, setDetails] = useState({
        first_name: '',
        last_name: '',
        email: '',
        company_name: '',
        country: '',
        zip: '',
        city: ''
    })
    const [roleId, setRoleId] = useState(2);

    useEffect(() => {
        getUser(id);
    }, [id])

    const getUser = async (id) => {
            try{
                const response = await axiosInstance.get('admin/users/'+id);
                if(response.status){
                    if(response.data?.data){
                        setDetails({
                            first_name: response.data.data.first_name,
                            last_name: response.data.data.last_name,
                            email: response.data.data.email,
                            company_name: response.data.data.company_name,
                            country: response.data.data.country,
                            zip: response.data.data.zip,
                            city: response.data.data.city
                        })
                        setRoleId(response.data.data.role?.id)
                    }
                }
            }catch{}
    }

    const isObjectValuesNotEmpty = (obj) => {
        return Object.values(obj).every(value => value !== '');
    };

    function objectToFormData(obj) {
        const formData = new FormData();
      
        for (const key in obj) {
          if (obj.hasOwnProperty(key)) {
            formData.append(key, obj[key]);
          }
        }
      
        return formData;
    }

    function formDataToUrlEncoded(formData) {
        const urlEncoded = [];
        formData.forEach((value, key) => {
          const encodedKey = encodeURIComponent(key);
          const encodedValue = encodeURIComponent(value);
          urlEncoded.push(`${encodedKey}=${encodedValue}`);
        });
        return urlEncoded.join('&');
      }

      
      

    const editUser = async (e) => {
        e.preventDefault();
        let role = parseInt(roleId);
        setError();
        if(isObjectValuesNotEmpty(details) && (role === 1 || role === 2)){
            if(EMAIL_REGEX.test(details.email)){
                const formData = objectToFormData(details);
                formData.append('role_id', role);
                const urlEncodedString = formDataToUrlEncoded(formData);
                const decodedString = decodeURIComponent(urlEncodedString);
                try{
                    const response = await axiosInstance.put('admin/users/'+id+'?'+ decodedString)
            
                    if(response.status){
                        setError('User edited successfully');
                    }
                }catch{
                    setError('Error while editing user.');
                }
            }else{
                setError('Invalid email address.');
            }
        }else{
            setError('Fill in the form.');
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setDetails((prevUser) => ({
          ...prevUser,
          [name]: value,
        }));
    };

    return (
        <div className="users">
            <div className="contentDD">
                <div className="mfd">
                    <KeyboardDoubleArrowLeftIcon onClick={() => window.location.pathname = "adminpanel/users"} />
                </div>
                <div className='userInfC'>
                    <div className='userDetailsW'>Edit User</div>
                    <div className='userD'>
                        <form>
                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <label htmlFor="first_name">First Name</label>
                                    <input type="text" value={details.first_name} name="first_name" onChange={handleInputChange} className="form-control" id="first_name"/>
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="last_name">Last Name</label>
                                    <input type="text" value={details.last_name} name="last_name" onChange={handleInputChange} className="form-control" id="last_name"/>
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="email">Email</label>
                                <input type="text" value={details.email} name="email" onChange={handleInputChange} className="form-control" id="email"/>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <label htmlFor="company_name">Company Name</label>
                                    <input type="text" value={details.company_name}  name="company_name" onChange={handleInputChange} className="form-control" id="company_name"/>
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="company_name">Role</label>
                                    <Form.Select value={roleId} name="role_id" onChange={(e) => setRoleId(e.target.value)} aria-label="Default select example">
                                        <option value="2">User</option>
                                        <option value="1">Admin</option>
                                    </Form.Select>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <label htmlFor="country">Country</label>
                                    <input type="text" value={details.country}  name="country" onChange={handleInputChange} className="form-control" id="country"/>
                                </div>
                                <div className="form-group col-md-4">
                                    <label htmlFor="city">City</label>
                                    <input type="text" value={details.city}  name="city" onChange={handleInputChange} className="form-control" id="city"/>
                                </div>
                                <div className="form-group col-md-2">
                                    <label htmlFor="zip">Zip</label>
                                    <input type="text" value={details.zip} name="zip"  onChange={handleInputChange}  className="form-control" id="zip"/>
                                </div>
                            </div>
                            <button type="submit" onClick={(e) => editUser(e)} className="btn btn-primary">Update</button>
                            <p style={{textAlign: 'start', paddingTop: '.4rem'}} className={error?.startsWith('User') ? 'p-success' : 'p-error'} aria-live='assertive'>{error}</p>
                        </form>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default UserDisplay;