import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate, Outlet } from 'react-router-dom';

import './App.css';

import LogIn from './pages/LogIn';
import Profile from './pages/Profile';
import Dashboard from './components/sidebarComp/Dashboard';
import Users from './components/sidebarComp/tables/Users';
import CreateUser from './components/sidebarComp/create/CreateUser';
import CreatePart from './components/sidebarComp/create/CreatePart';
import Parts from './components/sidebarComp/tables/Parts';
import CreateExport from './components/sidebarComp/create/CreateExport';
import Exports from './components/sidebarComp/tables/Exports';
import CatalogPage from './pages/CatalogPage';
import UserDisplay from './components/sidebarComp/update/UserDisplay';
import PartDisplay from './components/sidebarComp/update/PartDisplay'
import ExportDisplay from './components/sidebarComp/update/ExportDisplay';
import Navigation from './components/CatalogComponents/Navigation';
import Purchases from './pages/Purchases';
import { useDispatch, useSelector } from 'react-redux';
import Sidebar from './components/Sidebar';
import "./pages/AdminPanel.css";
import axiosInstance from './api/axios';
import { setUser } from './redux/reducers/userReducer';
import PurchasesAdmin from './components/sidebarComp/tables/Purchases';

const App = () => {
    const {token, id, userAttributes} = useSelector(state => state.user);
    const dispatch = useDispatch();

    useEffect(()=>{
        if(token && id === -1){
            getUser();
        }
    },[token,id])

    async function getUser(){
        const response1 = await axiosInstance.get('auth/me');
            
        if(response1.data?.data){
            dispatch(setUser(response1.data.data));
        }
    }
    return (
        <div className='app'>
            <BrowserRouter>
                <Routes>
                    <Route path='login' element={
                        token ? <Navigate to='/'/> : <LogIn /> } 
                    />
                    <Route
                        path='/*'
                        element={
                            <div>
                                <Navigation userAttributes={userAttributes} token={token} />
                                <Routes>
                                    <Route path='/' element={<CatalogPage token={token} />} />
                                    <Route path='profile' element={ !token ? <Navigate to='/login'/> : <Profile userAttributes={userAttributes} />} />
                                    <Route path='purchases' element={ !token ? <Navigate to='/login'/> :<Purchases />} />
                                </Routes>
                            </div>
                        }
                    />
                    
                    <Route path='adminpanel/*' element={
                        <>
                        {userAttributes?.role?.id === 1 &&
                         <div className="panelDiv" >
                            <Sidebar userAttributes={userAttributes}/>
                            <Outlet />
                            <Routes>
                                <Route path='/' element={<Dashboard />} />
                                <Route path='users' element={<Users />} />
                                <Route path='users/:id' element={<UserDisplay />} />
                                <Route path='createUser' element={<CreateUser /> } />
                                <Route path='createPart' element={<CreatePart />} />
                                <Route path='parts' element={<Parts />} />
                                <Route path='parts/:id' element={<PartDisplay />}/>
                                <Route path='createExport' element={ <CreateExport />} />
                                <Route path='exports' element={<Exports /> } />
                                <Route path='exports/:id' element={<ExportDisplay /> }/>
                                <Route path='purchases' element={<PurchasesAdmin /> } />
                            </Routes>
                        </div>
                        }
                        {userAttributes?.role?.id !== 1 && id === -1 && <Navigate to='/login'/>}
                        {userAttributes?.role?.id !== 1 && id !== -1 && <Navigate to='/'/>}
                        </>
                    }/>

                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;