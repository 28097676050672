import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import '../../pages/CatalogPage.css';
import SearchIcon from '@mui/icons-material/Search';
import { useEffect, useState } from 'react';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import Popup from "./Popup";
import axiosInstance from "../../api/axios";


const DisplayedParts = ({token}) => {
    const [parts, setParts] = useState(null);
    const [total_pages, setTotal] = useState(1)
    const [currentPage, setCurrentPage] = useState(1);

    const [isPopupOpen, setPopupOpen] = useState(false);
    const [popupData, setPopupData] = useState('');

    const [search, setSearch] = useState('');

    const filterParts = (value) => {
        if(value !== search){
            setSearch(value)
        }
    }

    useEffect(() => {
        getParts(1);
    },[])

    
    const getParts = async (page) => {
        setParts(null);
        setCurrentPage(page)
        try{
            const response = await axiosInstance.get('parts?page='+page);
            if(response.status){
                if(response.data?.data){
                    setParts(response.data.data);
                }
                if(response.data?.meta){
                    setTotal(response.data.meta?.last_page)
                    setCurrentPage(response.data.meta?.current_page)
                }
            }
        }catch{}
    }


    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };
    const handleRequestBuy = (itemData) => {
        setPopupOpen(true);
        setPopupData(itemData);
        document.body.classList.add('popup-open');
    };

    // Function to close the popup
    const handleClosePopup = () => {
        setPopupOpen(false);
        document.body.classList.remove('popup-open');
    };

    return (
        <>
         <div className='contentFilt'>
            <div className='backgroundImage'>
                <div className='textbACK'>
                    <span>Rive Transport Shop</span>
                    <span>Your One-Stop Shop for Truck Parts Excellence. Explore, shop, and experience the difference as you embark on a seamless and reliable road ahead.</span>
                </div>
            </div>
            <div className='filters'>
            <div className='searchContf'>
                <input id='searchInpf' value={search} onChange={(e) => filterParts(e.target.value)} type="text" placeholder='Search by name or serial number' />
                <div onClick={() => filterParts(search)} className='buttonFiltewr'>
                    <SearchIcon />
                </div>
            </div>
            </div>
            </div>
            <div className="bigCV">
                {isPopupOpen && <div className="overlay">
                    {isPopupOpen && <Popup onClose={handleClosePopup} data={popupData} />}
                </div>}
                <div className="displayedData">
                    {parts && parts.filter(x => {return x.name.toLowerCase().includes(search.toLowerCase()) || x.serial_number.toLowerCase().includes(search.toLowerCase())}).map((val, key) => {
                        return (
                            <div className="singleDisplay" key={key}>
                                <div className="imgD">
                                    <img src={val.part_picture} alt="" />
                                </div>
                                <div className="specsD">
                                    <div className="specsDleft">
                                        <div className="nameD">{val.name}</div>
                                        <div className="oemD">
                                            <span className="boldv">OEM number: </span>
                                            {val.oem}</div>
                                        <div className="serialN">
                                            <span className="boldv">Serial Number: </span>
                                            {val.serial_number}</div>
                                        <div className="priceD">{val.sale_price} €</div>
                                        <div></div>
                                    </div>
                                    <div className="specsDright">
                                        <div className="soecsDrightT">
                                            <div className="timeD">{/*05 јан 01:55*/}</div>
                                            <FavoriteBorderIcon />
                                        </div>
                                        {token &&
                                            <div className="requestBtnS">
                                                <button type="submit" className="btn btn-primary" onClick={() => handleRequestBuy(val)}>Request Buy</button>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    })}

                </div>
                <div className='paginationDD'>
                    <nav aria-label="...">
                        <ul className="pagination">
                            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                <span className="page-link" onClick={() => handlePageChange(currentPage - 1)}>
                                    <KeyboardDoubleArrowLeftIcon />
                                </span>
                            </li>
                            {Array.from({ length: total_pages }, (_, index) => (
                                <li className={`page-item ${currentPage === index + 1 ? 'active' : ''}`} key={index}>
                                    <span className="page-link" onClick={() => handlePageChange(index + 1)}>
                                        {index + 1}
                                        {currentPage === index + 1 && <span className="sr-only"></span>}
                                    </span>
                                </li>
                            ))}
                            <li className={`page-item ${currentPage === total_pages ? 'disabled' : ''}`}>
                                <span className="page-link" onClick={() => handlePageChange(currentPage + 1)}>
                                    <KeyboardDoubleArrowRightIcon />
                                </span>
                            </li>
                        </ul>
                    </nav>

                </div>
            </div>
        </>

    );
}

export default DisplayedParts;