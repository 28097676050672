import './Parts.css'
import SearchIcon from '@mui/icons-material/Search';
import ModeIcon from '@mui/icons-material/Mode';
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import axiosInstance from '../../../api/axios';

const Parts = () => {
    const [parts, setParts] = useState(null);
    const [total_pages, setTotal] = useState(1)
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState('');

    const navigate = useNavigate();   
                   // 8 - 8 = 0

    // Handle page change
    const handlePageChange = (newPage) => {
        getParts(newPage);
    };

    useEffect(() => {
        getParts(1);
    }, [])

    const getParts = async (page) => {
        setParts(null);
        setCurrentPage(page)
        try{
            const response = await axiosInstance.get('admin/parts?page='+page);
            if(response.status){
                if(response.data?.data){
                    setParts(response.data.data);
                }
                if(response.data?.meta){
                    setTotal(response.data.meta?.last_page)
                    setCurrentPage(response.data.meta?.current_page)
                }
            }
        }catch{}
    }

    const FilterX = (x) => {
        return x.oem.toLowerCase().includes(search.toLowerCase()) || x.serial_number.toLowerCase().includes(search.toLowerCase()) || x.name.toLowerCase().includes(search.toLowerCase())
    }

    return (
        <div className='parts' >
            <div className='titl'>Parts</div>
            <div className='partsC'>
                <div className='searchDiv'>
                    <div className='searchCont'>
                        <input style={{width: '100%'}} value={search} onChange={(e) => setSearch(e.target.value)} id='searchInp' type="text" placeholder='Search by name, oem or serial number' />
                        <SearchIcon />
                    </div>
                </div>
            </div>
            <div className='allpartsP'>
                {parts && parts.filter(x => FilterX(x)).map((val, key) => {
                    return (
                        <div className='singlePartP' key={key}>
                            <div className='leftP'>
                                <img src={val.part_picture} alt="" />
                            </div>
                            <div className='rightP'>
                                <div className='infoPartP'>
                                    <div className='inf'>
                                        <div className='namePart'>{val.name}</div>
                                        <div className='pricePartP'>Sale: {val.sale_price}€ <br/> Purchase: {val.purchase_price}€</div>
                                    </div>
                                    <div className='inf'>
                                        <div className='oemPart'><span className='boldTP'>OEM number:</span>{val.oem}</div>
                                        <div className='serialnPart'><span className='boldTP'>Serial Number:</span> {val.serial_number}</div>
                                    </div>
                                    <div className='dateUpdated'>{/*19 dec 13:04*/}</div>
                                    <div className='editB'>
                                    <ModeIcon onClick={() => {
                                    navigate(`/adminpanel/parts/${val.id}`);}}/>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    )
                })}


            </div>
            <div className='paginationDP'>
            <nav aria-label="...">
                    <ul className="pagination">
                        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                            <span className="page-link" onClick={() => handlePageChange(currentPage - 1)}>
                                <KeyboardDoubleArrowLeftIcon />
                            </span>
                        </li>
                        {Array.from({ length: total_pages }, (_, index) => (
                            <li className={`page-item ${currentPage === index + 1 ? 'active' : ''}`} key={index}>
                                <span className="page-link" onClick={() => handlePageChange(index + 1)}>
                                    {index + 1}
                                    {currentPage === index + 1 && <span className="sr-only"></span>}
                                </span>
                            </li>
                        ))}
                        <li className={`page-item ${currentPage === total_pages ? 'disabled' : ''}`}>
                            <span className="page-link" onClick={() => handlePageChange(currentPage + 1)}>
                                <KeyboardDoubleArrowRightIcon />
                            </span>
                        </li>
                    </ul>
                </nav>

            </div>
        </div>

    );
}

export default Parts;