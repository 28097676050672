import { useEffect, useState } from 'react';
import '../create/CreateExport.css';

import PaginationUsers from '../../../helpers/PaginationUsers';
import WideSearchSelect from '../../../helpers/PaginationSelect';
import axiosInstance from '../../../api/axios';

const CuzdiTruck = ({exportR}) => {
    const [parts, setParts] = useState(null);
    const [users, setUsers] = useState(null);
    const old_part = exportR.part?.id;
    const old_user = exportR.user?.id;
    const [part_id, setPartID] = useState();
    const [user_id, setUserID] = useState();

    const [details, setDetails] = useState({
        quantity: exportR?.quantity,
        notes: exportR?.notes,
    });

    const [error, setError] = useState();

    useEffect(() => {
        getParts();
        getUsers();
    }, [])

    const getParts = async () => {
        setParts(null);
        try{
            const response = await axiosInstance.get('admin/parts');
            if(response.status){
                if(response.data?.data){
                    setParts(response.data.data);
                }
            }
        }catch{}
    }

    const getUsers = async () => {
        setUsers(null);
        try{
            const response = await axiosInstance.get('admin/users');
            if(response.status){
                if(response.data?.data){
                    setUsers(response.data.data);
                }
            }
        }catch{}
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setDetails((prevUser) => ({
          ...prevUser,
          [name]: value,
        }));
    };

    const updateValueInParent = (newValue) => {
        setPartID(newValue);
    };

    const updateValueInParentUser = (newValue) => {
        setUserID(newValue);
    };

    const isObjectValuesNotEmpty = (obj) => {
        return Object.values(obj).every(value => value !== '');
    };

    function objectToFormData(obj) {
        const formData = new FormData();
      
        for (const key in obj) {
          if (obj.hasOwnProperty(key)) {
            formData.append(key, obj[key]);
          }
        }
      
        return formData;
    }

    function formDataToUrlEncoded(formData) {
        const urlEncoded = [];
        formData.forEach((value, key) => {
          const encodedKey = encodeURIComponent(key);
          const encodedValue = encodeURIComponent(value);
          urlEncoded.push(`${encodedKey}=${encodedValue}`);
        });
        return urlEncoded.join('&');
      }

    const updateExport = async (e) => {
        e.preventDefault();
        if(isObjectValuesNotEmpty(details)){
            if(!part_id){
                setPartID(old_part)
            }
            if(!user_id){
                setUserID(old_user)
            }

            const formData = objectToFormData(details);
            formData.append('user_id', user_id)
            formData.append('part_id', part_id)

            const urlEncodedString = formDataToUrlEncoded(formData);
            const decodedString = decodeURIComponent(urlEncodedString);

            try{
                const response = await axiosInstance.put('admin/exports/'+exportR.id + '?' + decodedString)
        
                if(response.status){
                    setError('Export updated successfully');
                }
            }catch{
                setError('Error while updating export.');
            }
        }else{
            setError('Fill in the form.');
        }
    }
    return (
        <div className='cuzdiTruck' >
            <div className='titlBig'>Cuzdi Truck</div>
            <div className='mars'>
                <div className="form-group col-md-12">
                    <label htmlFor="part_id"> Product </label>
                    <WideSearchSelect part_id={old_part} updateValueInParent={updateValueInParent} value={parts}/>
                </div>
                <div className="form-group col-md-12">
                    <label htmlFor="part_id"> User </label>
                    <PaginationUsers user_id={old_user} updateValueInParent={updateValueInParentUser} value={users}/>
                </div>
            </div>
            <div className='rows'>
                <div className="form-row">
                    <div className="form-group col-md-6">
                        <label htmlFor="quantity"> Quantity </label>
                        <input type="number" name="quantity"  value={details.quantity} onChange={handleInputChange} className="form-control" id="quantity" placeholder='0' />
                    </div>
                </div>
            </div>

            <div className="mb-66">
                <label htmlFor="notes" className="form-label">Text about the PART</label>
                <textarea value={details.notes} name="notes" onChange={handleInputChange} className="form-control" id="notes" rows="3" placeholder=''></textarea>
            </div>
            <div className='brnc'>
                <button onClick={(e) => updateExport(e)} type="submit" className="btn btn-primary">Update</button>
                <p style={{textAlign: 'start', paddingTop: '.4rem'}} className={error?.startsWith('Export') ? 'p-success' : 'p-error'} aria-live='assertive'>{error}</p>
            </div>
        </div>
    );
}

export default CuzdiTruck;