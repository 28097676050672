
import '../../pages/CatalogPage.css';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import axiosInstance from '../../api/axios';



const Popup = ({ onClose, data }) => {

    const [quantityValue, setQuantityValue] = useState(1);
    const [error, setError] = useState();

    const plusQuantityValue = () =>{
        setQuantityValue(quantityValue+1);
    }
    const minusQuantityValue = () =>{
        if(quantityValue>1){
            setQuantityValue(quantityValue-1);
        }
    }

    const completeRequest = async (e) => {
        e.preventDefault();
        setError();
        try{
            const formData = new FormData();
            formData.append('part_id', data.id);
            formData.append('quantity', quantityValue);
            const response = await axiosInstance.post('purchases', formData)

            if(response.status){
                setError('Purchase made successfully. Wait for the admin to confirm it.');
            }else{
                setError('The purchase could not be proccessed. Try again later');
            }
        }catch(err){
            setError('The purchase could not be proccessed. Try again later');
        }
    }

    return (
        <div className='popupD'>
            <div className='contentPoput'>
                <div className='closeDivPo' onClick={onClose}>
                    <CloseIcon onClick={onClose} />
                </div>
                <div className="popupContent">
                    <div className='imgWP'>
                        <img src={data.part_picture} alt="" />
                        <div className='poputData'>
                            <div className="nameD">{data.name}</div>
                            <div className="oemD">
                                <span className="boldv">OEM number: </span>
                                {data.oem}
                            </div>
                            <div className="serialN">
                                <span className="boldv">Serial Number: </span>
                                {data.serial_number}
                            </div>
                            <div className="serialN">
                                <span className="boldv">Price: </span>
                                {data.sale_price} €
                            </div>
                            <div className='quantityD'>
                                <div className='quantityW'>Quantity</div>
                                <div className='quantityCont'>
                                    <div className='btnQ' onClick={minusQuantityValue} onMouseDown={(e) => e.preventDefault()}>-</div>
                                    <div className='valueQ'>{quantityValue}</div>
                                    <div className='btnQ' onClick={plusQuantityValue} onMouseDown={(e) => e.preventDefault()}>+</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='btncompolete'>
                        <button onClick={(e) => completeRequest(e)} type="submit" className="btn btn-primary">Complete</button>
                    </div>
                    <div className='btncompolete' style={{marginTop: '0'}}>
                        <p style={{textAlign: 'start', paddingTop: '.4rem'}} className={error?.startsWith('Purchase') ? 'p-success' : 'p-error'} aria-live='assertive'>{error}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Popup;