import Logo from '../images/pt.png'
import 'bootstrap/dist/css/bootstrap.min.css';
import "./LogIn.css";
import { useRef } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import axiosInstance from '../api/axios';
import { useDispatch } from 'react-redux';
import { setToken, setUser } from '../redux/reducers/userReducer';

const EMAIL_REGEX = /^[a-zA-Z0-9.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
const PASS_REGEX = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;

const LogIn = () => {
    const userRef = useRef();
    const errRef = useRef();

    const dispatch = useDispatch();

    const [email, setEmail] = useState('');
    const [validEmail, setValidEmail] = useState(false);

    const [password, setPass] = useState('');
    const [validPass, setValidPass] = useState(false);
    const [passFocus, setPassFocus] = useState(false);

    const [errMsg, setErrMsg] = useState('');

    useEffect(() => {
        userRef.current.focus();
    }, [])

    useEffect(() => {
        const result = EMAIL_REGEX.test(email);
        setValidEmail(result);
    }, [email])

    useEffect(() => {
        const result = PASS_REGEX.test(password);
        setValidPass(result);
    }, [password])

    useEffect(() => {
       setErrMsg('');
    }, [email, password])

    const hangleSubmit = async (e) => {
        e.preventDefault();

        if(!validEmail || !validPass){
            setErrMsg("Invalid credentials");
            return;
        }

        try{
            const formData = new FormData();
            formData.append('email', email);
            formData.append('password', password);

            const response = await axiosInstance.post('auth/login', formData,{
                headers: {'Content-Type' : 'application/json'}
            })
            if(response.data?.data){
                dispatch(setToken(response.data.data?.token));
            }
            
            const response1 = await axiosInstance.get('auth/me');
            
            if(response1.data?.data){
                dispatch(setUser(response1.data.data));
            }
                
        }catch(err){
            if(!err?.response){
                setErrMsg('No server response. Try again later');
            }else if (err.response?.status === 401){
                setErrMsg('Email & Password does not match.')
            }else{
                setErrMsg('Log in failed')
            }
        }
    }

    return (
        <div className="main-div" >
            <div className="content">
                <div className="logo">
                    <img className='logoImg' src={Logo} alt="" />
                </div>
                <div className="card">
                    <div className='signInT'>Sign In</div>
                    <form className='signInInf'>
                        <label htmlhtmlFor="useremail">Email 
                            <span className={validEmail ? "valid" : "hide"}>
                                *
                            </span>
                            <span className={validEmail || !email ? "hide" : "invalid"}>
                                *
                            </span>
                        </label>
                        <input 
                            type="email" 
                            ref={userRef}
                            autoComplete='off'
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            className="form-control" 
                            id="useremail" 
                            aria-invalid={validEmail ? "false" : "true"}
                            aria-describedby="uidnote" 
                            placeholder="Enter email" 
                        />
                        <label htmlhtmlFor="password">Password
                            <span className={validPass ? "valid" : "hide"}>
                                *
                            </span>
                            <span className={validPass || !password ? "hide" : "invalid"}>
                                *
                            </span>
                        </label>
                        <input 
                            type="password" 
                            className="form-control" 
                            id="password" 
                            onChange={(e) => setPass(e.target.value)}
                            aria-invalid={validPass ? "false" : "true"}
                            aria-describedby='pwdnote'
                            placeholder="Password"
                            onFocus={() => setPassFocus(true)}
                            onBlur={() => setPassFocus(false)}
                        ></input>
                        <p id="pwdnote" className={passFocus && !validPass ? "instructions" : "hide"}>
                            <FontAwesomeIcon style={{paddingRight: '.4rem'}} icon={faInfoCircle}/>
                            Require a minimum of eight characters,
                            including at least one letter and one number.
                        </p>
                        {/* 
                        <div className='remDiv'>
                            <input className="form-check-input" type="checkbox" value="" id="rememberMe" />
                            <label className="form-check-label" htmlFor="defaultCheck1">Remember me</label>
                        </div>
                        */}
                    </form>
                    <button
                    onClick={(e) => hangleSubmit(e)}
                    disabled={!validEmail || !validPass ? true : false}
                    type="submit" className="btn btn-primary">Sign In</button>
                    <p ref={errRef} className={errMsg ? 'err-msg' : 'offscreen'} aria-live='assertive'>{errMsg}</p>
                </div>
            </div>
        </div>
    );
}

export default LogIn;