import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    token: null,
    id: -1,
    userAttributes: null,
    profile_photo: null
};

const UserSlice = createSlice({
    name: "user",
    initialState: initialState,
    reducers: {
        setToken: (state, action) => {
        
            state.token = action.payload;
        },
        setUser: (state, action) => {
            state.id = action.payload?.id;
            state.userAttributes = action.payload;
            state.profile_photo = action.payload?.profile_photo;
        },
        setImage: (state,action) => {
         
            if(state.userAttributes){
                state.userAttributes.profile_photo = action.payload?.profile_picture;
            }
            state.profile_photo = action.payload?.profile_picture;
        },
        logOutUser: (state) => {
            state.token = null;
            state.id = -1;
            state.userAttributes = null;
        }
    }
});

export const {setToken, setUser, logOutUser, setImage} = UserSlice.actions;

export default UserSlice.reducer;