import React, { PureComponent } from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import './Dashboard.css' //samo za fontot na data na chartut


export default class Example extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      transformedData: this.props.chartData
    };
  }

  static demoUrl = 'https://codesandbox.io/s/simple-line-chart-kec3v';

  render() {
    const {chartData} = this.props;
    
    function transform1Data(input) {
      const result = [];
    
      for (const date in input) {
        if (input.hasOwnProperty(date)) {
          const data = input[date];

          result.push({
            name: date,
            users: data.users,
            parts: data.parts,
            exports: data.exports,
            purchases: data.purchases,
          });
        }
      }
    
      return result;
    }

    const transformData = (data) => {
      return transform1Data(data);
    }
   
    return (
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          width={500}
          height={300}
          data={transformData(chartData)}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Area type="monotone" dataKey="users" stroke="#387ab4" fill="#387ab4" fillOpacity={0.1} />
          <Area type="monotone" dataKey="parts" stroke="#63b761" fill="#63b761" fillOpacity={0.1}  />
          <Area type="monotone" dataKey="exports" stroke="#eeac56" fill="#eeac56" fillOpacity={0.1} />
          <Area type="monotone" dataKey="purchases" stroke="#d65351" fill="#d65351" fillOpacity={0.3} activeDot={{ r: 8 }} />
        </AreaChart>
      </ResponsiveContainer>
    );
  }
}
