import { useParams } from "react-router-dom";
import { PartData } from "../PartData"
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import './DisplayS.css'
import { useEffect, useState } from "react";
import axiosInstance from "../../../api/axios";


const PartDisplay = () => {
    const { id } = useParams();
    const [error, setError] = useState();
    const [selectedFile, setSelectedFile] = useState(null);
    const [isFileValid, setFileValid] = useState(true);
    const [details, setDetails] = useState({
        name: '',
        oem: '',
        serial_number: '',
        purchase_price: '',
        sale_price: '',
    })

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setDetails((prevUser) => ({
          ...prevUser,
          [name]: value,
        }));
    };

    useEffect(() => {
        getPart(id);
    }, [id])

    const getPart = async (id) => {
        try{
            const response = await axiosInstance.get('admin/parts/'+id);
            if(response.status){
                if(response.data?.data){
                    setDetails({
                        name: response.data.data.name,
                        oem: response.data.data.oem,
                        serial_number: response.data.data.serial_number,
                        purchase_price: response.data.data.purchase_price,
                        sale_price: response.data.data.sale_price,
                    })
                }
            }
        }catch{}
    }

    const handleFileChange = (event) => {
        const file = event.target.files[0];
    
        if (file) {
          // Check if the file is an image
          if (file.type.startsWith('image/')) {
            // Check if the file size is less than or equal to 500KB
            if (file.size <= 500 * 1024) {
              setSelectedFile(file);
              setFileValid(true);
            } else {
              // File size exceeds 500KB
              setSelectedFile(null);
              setFileValid(false);
            }
          } else {
            // File is not an image
            setSelectedFile(null);
            setFileValid(false);
          }
        } else {
          // No file selected
          setSelectedFile(null);
          setFileValid(false);
        }
      };

    const isObjectValuesNotEmpty = (obj) => {
        return Object.values(obj).every((value) => value !== '' && value !== null);
    };

    function objectToFormData(obj) {
        const formData = new FormData();
      
        for (const key in obj) {
          if (obj.hasOwnProperty(key)) {
            formData.append(key, obj[key]);
          }
        }
      
        return formData;
    }

    const editPart = async (e) => {
        e.preventDefault()
        setError()

        if(isObjectValuesNotEmpty(details)){
                const formData = objectToFormData(details);
                
                if(selectedFile){
                    if(isFileValid){
                        formData.append('file', selectedFile)
                    }else{
                        setError('Image is not in valid format. Remove it or add another.');
                        return;
                    }
                }
                try{
                    const response = await axiosInstance.post('admin/parts/'+id, formData)

                    if(response.status){
                        setError('Part edited successfully');
                    }
                }catch(err){
                    if(err.response?.data?.message){
                        setError(err.response?.data?.message);
                    }else{
                        setError('Error while editing part.');
                    }
                    
                }
        }else{
            setError('Fill in the form.');
        }
    }

    
    return (
        <div className="users">
            <div className="contentDD">
                <div className="mfd">
                    <KeyboardDoubleArrowLeftIcon onClick={() => window.location.pathname = "adminpanel/parts"} />
                </div>
                <div className='userInfC'>
                    <div className='userDetailsW'>Edit Part</div>
                    <div className='userD'>
                    <form>
                        <div className="form-row">
                            <div className="form-group col-md-4">
                                <label htmlFor="name">Part Name</label>
                                <input type="text" name="name" value={details.name} onChange={handleInputChange} className="form-control" id="name" placeholder="" />
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="serial_number">Serial Number</label>
                            <input type="text" name="serial_number" value={details.serial_number} onChange={handleInputChange} className="form-control" id="serial_number" placeholder="" />
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-3">
                                <label htmlFor="oem"> OEM Number</label>
                                <input type="number" name="oem" value={details.oem} onChange={handleInputChange} className="form-control" id="oem" placeholder="" />
                            </div>
                            <div className="form-group col-md-3">
                                <label htmlFor="purchase_price">Purchase Price</label>
                                <input type="number" name="purchase_price"  value={details.purchase_price} onChange={handleInputChange} className="form-control" id="purchase_price" placeholder='€' />
                            </div>
                            <div className="form-group col-md-3">
                                <label htmlFor="sale_price">Sale Price</label>
                                <input type="number" name="sale_price" value={details.sale_price} onChange={handleInputChange} className="form-control" id="sale_price" placeholder='€' />
                            </div>
                        </div>
                        <div className="custom-file">
                            <input 
                            type="file" 
                            accept="image/*"
                            className={`custom-file-input ${isFileValid ? '' : 'is-invalid'}`}
                            id="validatedCustomFile" 
                            required 
                            onChange={handleFileChange}
                            />
                            <div className="invalid-feedback">{isFileValid ? '' : 'Please choose a valid image (max 500KB).'}</div>
                        </div>
                        <button type="submit" onClick={(e) => editPart(e)} className="btn btn-primary">Update</button>
                        <p style={{textAlign: 'start', paddingTop: '.4rem'}} className={error?.startsWith('Part') ? 'p-success' : 'p-error'} aria-live='assertive'>{error}</p>
                    </form>
                    </div>
                </div>
            </div>

        </div>
    );

    
}

export default PartDisplay;