import "./Sidebar.css";
import { SidebarData } from "./SidebarData";
import SidebarItem from "./SidebarItem"
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { useState,useEffect} from 'react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useNavigate } from "react-router-dom";

const Sidebar = ({userAttributes}) => {
    const [sidebarVisible, setSidebarVisible] = useState(false);
    const navigate = useNavigate();

    const handleBurgerClick = () => {
        setSidebarVisible(!sidebarVisible);
    };
    
    useEffect(() => {
        // Add or remove a class to body when sidebar state changes
        document.body.classList.toggle('no-scroll', sidebarVisible);
    }, [sidebarVisible]);

    return (
        <div className="bothSideb" >
            <div className={`burgerBtn ${sidebarVisible ? 'closed' : 'active'}`} onClick={handleBurgerClick}>
                <MenuIcon />
            </div>
            
            <div className={`sideBar ${sidebarVisible ? 'active' : 'closed'}`}>
                <div className="admDiv">
                    <div className="imgDiv">
                        {userAttributes?.profile_photo &&
                            <img className="imgUser" src={userAttributes?.profile_photo} alt="" />
                        }
                        {!userAttributes?.profile_photo &&
                            <AccountCircleIcon style={{width: '100%', height: '100%'}} />
                        }
                    </div>
                    <span>{userAttributes?.first_name} {userAttributes?.last_name}</span>
                    <div className="closeSbu"><CloseIcon onClick={handleBurgerClick} /></div>
                </div>
                <div className="bothS">
                    <div className="topSideBar">
                        <div className="sidebar">
                            {SidebarData.map((item, index) => <SidebarItem key={index} item={item} />)}
                        </div>
                    </div>
                    <div className="bottomSideBar">
                        <div onClick={() => {navigate('/')}} className="sidebarL">
                            <span >
                                <div className="iconsL">< LogoutIcon /></div>
                                <div className="titleSub"> Back </div>
                            </span>
                        </div>
                        <div className="pow">Powered by <span>M-Tech</span> 2024</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Sidebar;