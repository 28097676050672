
import "./Profile.css"
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import React, { useRef, useState } from 'react';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import { motion, AnimatePresence } from 'framer-motion';
import { Avatar } from "@mui/material";
import { Badge } from "react-bootstrap";
import axiosInstance from "../api/axios";
import { useDispatch } from "react-redux";
import { setImage, setUser } from "../redux/reducers/userReducer";


const EMAIL_REGEX = /^[a-zA-Z0-9.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
const PASS_REGEX = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;

const Profile = ({userAttributes}) => {
    const [showChangePwContent, setShowChangePwContent] = useState(false);
    const [email, setEmail] = useState(userAttributes ? userAttributes.email : '');
    const [company, setCompany] = useState(userAttributes ? userAttributes.company_name : '');
    const [firstName, setName] = useState(userAttributes ? userAttributes.first_name : '');
    const [lastName, setLName] = useState(userAttributes ? userAttributes.last_name : '');

    const [formError, setFormError] = useState();

    const [currPass, setCurrPass] = useState('');
    const [newPass, setNPass] = useState('');
    const [repeatPass, setRPass] = useState('');

    const [errPass, setErrorPass] = useState();

    const [file, setFile] = useState(null);
    const [imgError, setImgError] = useState();
    const [blob, setBlob] = useState(null);

    const fileInputRef = useRef(null);
    const dispatch = useDispatch();

    const changePassword = async (e) =>{
        e.preventDefault();
        setErrorPass();

        if(currPass !== '' && newPass !== '' && repeatPass !== ''){
            if(newPass === repeatPass){
                if(currPass === userAttributes?.password){
                    if(PASS_REGEX.test(newPass)){
                        let formdata = new FormData();
                        formdata.append('password', newPass);
                        formdata.append('password_confirmation', repeatPass);
            
                        const urlEncodedString = formDataToUrlEncoded(formdata);
                        const decodedString = decodeURIComponent(urlEncodedString);
            
                        try{
                            const result = await axiosInstance.put('auth/'+userAttributes?.id + '?' + decodedString)
            
                            if(result.status){
                                dispatch(setUser(result.data?.data))
                                setErrorPass('Password changed successfully.')
                            }
                        }catch{
                            setErrorPass('Error updating. Try again later.')
                        }
                    }else{
                        setErrorPass('The password does not contain a minimum of eight characters, including at least one letter and one number.')
                    }
                   
                }else{
                    setErrorPass('Old password is incorrect.');
                }
            }else{
                setErrorPass('New passwords do not match.');
            }
        }else{
            setErrorPass('Fill in the form');
        }
    }

    const saveImgChanges = async (e) => {
        e.preventDefault();
        let formdata = new FormData();
        formdata.append('file', file);

        try{
            const result = await axiosInstance.post('auth/me/set-profile-image', formdata)

            if(result.status){
                dispatch(setImage(result.data))
                setImgError('Picture updated successfully.')
            }
        }catch(err){
            setImgError('Error updating. Try again later.')
        }
    }

    const submitEdit = async (e) => {
        e.preventDefault();
        setFormError();

        if(email !== '' && company !== '' && firstName !== '' && lastName !== ''){
            if(EMAIL_REGEX.test(email)){
                let formdata = new FormData();
                formdata.append('email', email);
                formdata.append('company_name', company);
                formdata.append('first_name', firstName);
                formdata.append('last_name', lastName);

                const urlEncodedString = formDataToUrlEncoded(formdata);
                const decodedString = decodeURIComponent(urlEncodedString);

                try{
                    const result = await axiosInstance.put('auth/'+userAttributes?.id + '?' + decodedString)

                    if(result.status){
                        dispatch(setUser(result.data?.data))
                        setFormError('Information updated successfully.')
                    }
                }catch{
                    setFormError('Error updating. Try again later.')
                }
            }else{
                setFormError('The email is not in the correct format.');
            }
        }else{
            setFormError('Fill in the form');
        }
    }
    const handleUpload = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setImgError();
        setBlob(null);
    
        if (file && file.type.startsWith('image/') && file.size <= 500 * 1024) {
     
            setFile(file);
            try{
                fileToBlob(file).then((blob) => {
                    const imageUrl = URL.createObjectURL(blob);
                   
                    setBlob(imageUrl)
                });
            }catch{}
        }else if (file.size > 500 * 1024) {
            setImgError('The image size must not surpass 500KB');
        }else{
            setImgError('Invalid file type or no file selected');
        }
    };

    const removeImg = () => {
        setBlob(null);
        setFile(null);
        setImgError();
    }

    const fileToBlob = (file) => {
        return new Promise((resolve) => {
            const reader = new FileReader();
        
            reader.onload = () => {
            const blob = new Blob([reader.result], { type: file.type });
            resolve(blob);
            };
        
            reader.readAsArrayBuffer(file);
        });
    };

    const handlePasswordChangeClick = () => {
        setShowChangePwContent(!showChangePwContent);
    };

    function formDataToUrlEncoded(formData) {
        const urlEncoded = [];
        formData.forEach((value, key) => {
          const encodedKey = encodeURIComponent(key);
          const encodedValue = encodeURIComponent(value);
          urlEncoded.push(`${encodedKey}=${encodedValue}`);
        });
        return urlEncoded.join('&');
      }
      

    return (
        <div className="profileDiv">
            <div className="prifleCont">
                <div className="profileTtls">Account Settings</div>
                <div className="mainSettings">
                    <AnimatePresence >
                        {showChangePwContent ? (
                            <motion.div
                                key="changePwContent"
                                className="changePwContent"
                                initial={{ x: "+100%", opacity: 0 }}
                                animate={{ x: 0, opacity: 1 }}
                                exit={{ x: "-100%", opacity: 0 }}
                                transition={{ duration: 0.5, ease: "easeInOut" }}
                                layout
                            >
                                <KeyboardDoubleArrowLeftIcon onClick={handlePasswordChangeClick} />
                                <form>
                                    <div className="form-group">
                                        <label htmlhtmlFor="currPass" className="col-sm-2 col-form-label ">Current Password</label>
                                        <div className="col-sm-10">
                                            <input type="password" value={currPass} onChange={(e) => setCurrPass(e.target.value)} className="form-control form-control-sm" id="currPass" placeholder="Old Password*" />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlhtmlFor="newPass" className="col-sm-2 col-form-label ">New Password</label>
                                        <div className="col-sm-10">
                                            <input type="password" value={newPass} onChange={(e) => setNPass(e.target.value)} className="form-control form-control-sm" id="newPass" placeholder="New Password*" />
                                        </div>
                                    </div>
                                    <span className="gpass">
                                    Require a minimum of eight characters, including at least one letter and one number.
                                    </span>
                                    <div className="form-group">
                                        <label htmlhtmlFor="repeatPass" className="col-sm-2 col-form-label ">Repeat Password</label>
                                        <div className="col-sm-10">
                                            <input type="password" value={repeatPass} onChange={(e) => setRPass(e.target.value)} className="form-control form-control-sm" id="repeatPass" placeholder="Repeat Password*" />
                                        </div>
                                    </div>
                                    <div>
                                        <button onClick={(e) => changePassword(e)} type="submit" id="bnProfile" className="btn btn">Change</button>
                                        <p style={{textAlign: 'left', paddingTop: '.4rem'}} className={errPass?.startsWith('Password') ? "p-success" : 'p-error'}>{errPass}</p>
                                    </div>
                                </form>

                            </motion.div>
                        ) : (
                            <motion.div
                                key="bothSettings"
                                className="bothSettings"
                                initial={{ x: "+100%", opacity: 0 }}
                                animate={{ x: 0, opacity: 1 }}
                                exit={{ x: "-100%", opacity: 0 }}
                                transition={{ duration: 0.5, ease: "easeInOut" }}
                                layout
                            >
                                <form>
                                    <div className="form-group-inline">
                                        <div className="form-group">
                                            <label htmlhtmlFor="name" className="col-sm-2 col-form-label ">First Name</label>
                                            <div className="col-sm-10">
                                                <input type="email" id="name" className="form-control form-control-sm" onChange={(e) => setName(e.target.value)} value={firstName} />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlhtmlFor="Lname" className="col-sm-2 col-form-label ">Last Name</label>
                                            <div className="col-sm-10">
                                                <input type="email" id="Lname" className="form-control form-control-sm" onChange={(e) => setLName(e.target.value)} value={lastName} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlhtmlFor="email" className="col-sm-2 col-form-label ">Email</label>
                                        <div className="col-sm-10">
                                            <input  type="email" id="email" className="form-control form-control-sm" onChange={(e) => setEmail(e.target.value)} value={email} />
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <label htmlhtmlFor="password" className="col-sm-2 col-form-label ">Password</label>
                                        <div className="col-sm-10">
                                            <input type="password" disabled readOnly className="form-control form-control-sm" id="password" value={'dddddassf'} />
                                            <span className="changePw" onClick={handlePasswordChangeClick}>Change</span>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <label htmlhtmlFor="company" className="col-sm-2 col-form-label ">Company</label>
                                        <div className="col-sm-10">
                                            <input type="text" className="form-control form-control-sm" id="company" value={company} onChange={(e) => setCompany(e.target.value)} />
                                        </div>
                                    </div>

                                    <button onClick={(e) => submitEdit(e)} type="submit" id="bnProfile" className="btn btn">Save</button>
                                    <p style={{textAlign: 'left', paddingTop: '.4rem'}} className={!formError?.startsWith('Information') ? "p-error" : 'p-success'}>{formError}</p>
                                </form>
                                <div className="imgaDivP">
                                    {!file &&
                                        <>
                                            {!userAttributes?.profile_photo &&
                                                <AccountCircleIcon />
                                            }
                                            {userAttributes?.profile_photo &&
                                                <div style={{position: 'relative'}}>
                                                    <Avatar style={{border: '1px solid',marginBottom: '.4rem'}} alt="img"  sx={{ width: 70, height: 70 }} src={userAttributes.profile_photo}></Avatar>
                                                </div>
                                            }
                                        </>
                                        
                                    }{
                                        file && blob && 
                                            <div style={{position: 'relative'}}>
                                                <Avatar style={{border: '1px solid',marginBottom: '.4rem'}} alt="img"  sx={{ width: 70, height: 70 }} src={blob}></Avatar>
                                                <Badge onClick={() => removeImg()} className="badge_profile" badgecontent={4}>X</Badge>
                                            </div>
                                    }
                                    <div onClick={(e) => blob ? saveImgChanges(e) : handleUpload()} style={blob ? {background: 'green'} : {}} className="uploadPicture">{blob ? 'Save Picture' : 'Upload a picture'}</div>
                                    <input
                                        type="file"
                                        ref={fileInputRef}
                                        style={{ display: 'none' }}
                                        onChange={handleFileChange}
                                    />
                                    {imgError && 
                                    <p className={imgError?.startsWith('Picture') ? 'p-success' : 'p-error'}>{imgError}</p>}
                                </div>
                            </motion.div>
                        )}
                    </AnimatePresence>
                </div>
            </div>
        </div>
    );
}

export default Profile;