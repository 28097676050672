
import './Users.css';
import ModeIcon from '@mui/icons-material/Mode';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from 'react'
import axiosInstance from '../../../api/axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeLowVision, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const Users = () => {
    const [seePass, setSeePass] = useState(-1);
    const [users, setUsers] = useState(null);
    const [total_pages, setTotal] = useState(1)
    const [currentPage, setCurrentPage] = useState(1);

    const [search, setSearch] = useState('');

    const navigate = useNavigate();

    // Handle page change
    const handlePageChange = (newPage) => {
        getUsers(newPage);
    };

    const changeVisibility = (id) => {
        if(id === seePass){
            setSeePass(-1)
        }else{
            setSeePass(id)
        }
    }

    useEffect(() => {
        getUsers(1);
    }, [])

    const getUsers = async (page) => {
        setUsers(null);
        setCurrentPage(page)
        try{
            const response = await axiosInstance.get('admin/users?page='+page);
            if(response.status){
                if(response.data?.data){
                    setUsers(response.data.data);
                }
                if(response.data?.meta){
                    setTotal(response.data.meta?.last_page)
                    setCurrentPage(response.data.meta?.current_page)
                }
            }
        }catch{}
    }

    const FilterX = (x) => {
        return x.first_name.toLowerCase().includes(search.toLowerCase()) || x.last_name.toLowerCase().includes(search.toLowerCase()) || x.company_name.toLowerCase().includes(search.toLowerCase())
        || x.email.toLowerCase().includes(search.toLowerCase())  
    }

    return (
        <div className="users">
            <div className='titl'>Users</div>
            <div className='usersC'>
                <div className='searchDiv'>
                    <div className='searchCont'>
                        <input style={{width: '100%'}} value={search} onChange={(e) => setSearch(e.target.value)} id='searchInp' type="text" placeholder='Search by name, company or email' />
                        <SearchIcon />
                    </div>
                </div>
                <div className='scrollableDivUser'>
                    <div className='usersT'>
                        <div className='firstT'>First Name</div>
                        <div className='firstT'>Last Name</div>
                        <div className='firstT'>Email</div>
                        <div className='firstT'>Company</div>
                        <div className='firstT'>Type</div>
                        <div className='firstT'>Password</div>
                        <div className='firstT'></div>
                    </div>
                    <div className='allUsers'>
                        {users && users.filter(x => FilterX(x)).map((val, key) => {
                            return (
                                <div className='singleUser' key={key}>
                                    <div className='firstV'>{val.first_name}</div>
                                    <div className='firstV'>{val.last_name}</div>
                                    <div className='firstV'>{val.email}</div>
                                    <div className='firstV'>{val.company_name}</div>
                                    <div className='firstV'>{val.role?.name}</div>
                                    <div className={seePass === val.id ? 'firstV' : 'password-text firstV'}>{val.password} <FontAwesomeIcon onClick={() => changeVisibility(val.id)} className='table_font_awesome' icon={seePass === val.id ? faEyeSlash : faEye} /></div>
                                    <div className='firstV' onClick={() => {
                                        navigate(`/adminpanel/users/${val.id}`);
                                    }}><ModeIcon /></div>
                                </div>

                            )
                        })}
                    </div>
                </div>

            </div>
            <div className='pagiDiv'>
                <nav aria-label="...">
                    <ul className="pagination">
                        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                            <span className="page-link" onClick={() => handlePageChange(currentPage - 1)}>
                                <KeyboardDoubleArrowLeftIcon />
                            </span>
                        </li>
                        {Array.from({ length: total_pages }, (_, index) => (
                            <li className={`page-item ${currentPage === index + 1 ? 'active' : ''}`} key={index}>
                                <span className="page-link" onClick={() => handlePageChange(index + 1)}>
                                    {index + 1}
                                    {currentPage === index + 1 && <span className="sr-only"></span>}
                                </span>
                            </li>
                        ))}
                        <li className={`page-item ${currentPage === total_pages ? 'disabled' : ''}`}>
                            <span className="page-link" onClick={() => handlePageChange(currentPage + 1)}>
                                <KeyboardDoubleArrowRightIcon />
                            </span>
                        </li>
                    </ul>
                </nav>
            </div>


        </div>

    );
}

export default Users;